


import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "@/config";
import Axios from "axios";

import moment from "moment";
import { mixins } from "vue-class-component";
import Datepicker from "vuejs-datepicker";

@Component({components: {Datepicker} })
export default class UserLogs extends Vue {
  public userInfo = [];  
  public userLoader = false;
  public showLoaderScroll = false;
  public currentLengthOnScroll = 0;
  public totalCount = 0;
  public skip = 0;
  public searchByType = null;
  public type = null;
  public state: any = {};
  public disable = {};
  public searchString = null;

  public get hasMoreData() {
    return this.currentLengthOnScroll < this.totalCount;
  }

  public async searchByDate(type) {
      try {
        let startDate = this.state.from.getDate();
        let startMonth = this.state.from.getMonth();
        let startYear = this.state.from.getFullYear();
        this.state.from = new Date(startYear, startMonth, startDate);
        let from = this.state.from;
  
        let endDate = this.state.to.getDate();
        let endMonth = this.state.to.getMonth();
        let endYear = this.state.to.getFullYear();
        this.state.to = new Date(endYear, endMonth, endDate);
        let to = this.state.to;
  
        await this.userDetails(type, from, to);
      } catch (error) {
        console.log(error);
      }
    }

public async userDetails(mountCall, from, to) {
    try {
      this.skip = mountCall ? 0 : this.skip + 25;
      let body = {
        skip: this.skip,
        limit: 25,
        type: this.searchByType,
        function:this.type,
        from: from.getTime(),
        to: to.getTime(),
        searchString: this.searchString,
      }
      if (mountCall) {
        this.userLoader = true;
      }
      const response = await Axios.get(
        BASE_API_URL + "superAdmin/getUserLogs",
        { params: body },
      );

      if (mountCall) {
        this.userInfo = response.data.data;
        this.totalCount = response.data.totalCount;
      } else {
        this.userInfo = this.userInfo.concat(response.data.data);
      }
      this.currentLengthOnScroll = this.userInfo.length;
    } catch(error) {
        console.error(error);
    } finally {
      this.showLoaderScroll = false;
      this.userLoader = false;
    }
   
}

public async showMoreDataOnScroll(){
  if (!this.showLoaderScroll && this.hasMoreData) {
    this.showLoaderScroll = true;
    await this.searchByDate(false);
  }
}


public async getDataOnChange(){
  await this.searchByDate(true);
}

mounted(){ 
   let date = new Date().getDate();
   let month = new Date().getMonth();
   let year = new Date().getFullYear();
   this.state.from = new Date(year, month, date);
   this.state.to = new Date();

   this.disable = {
     from: this.state.from,
     to: this.state.to,
   };
    this.userDetails(true,this.state.from, this.state.to)
   }
}
